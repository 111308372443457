@page {
  margin: 0;
}

@media print {
  html {
    font-size: 10px;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .l-header__body.js-header__body--fixed {
    position: relative;
    background-color: transparent;
  }

  .l-main > section {
    page-break-after: always;
  }

  [data-animation="scale-in"] {
    opacity: 1;
  }

  .p-message__eyecatch,
  .p-company__eyecatch {
    z-index: -1;
  }

  .p-message__title-wrap.js-clone-node,
  .p-company__title-wrap.js-clone-node {
    display: none;
  }

  .p-history__sticky {
    position: relative;
  }
}
