.p-business {
  position: relative;
  padding: var(--global-section-padding-large) 0;
  background-color: var(--primary__color--hex);

  // .p-business__inner
  &__inner {
    display: grid;
    gap: rr(28);

    @include media-query-small {
      gap: rr(120);
    }
  }

  // .p-company__head
  &__head {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    padding-right: var(--global-inner-large);

    @include media-query-small {
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  // .p-company__eyecatch
  &__eyecatch {
    @include aspect-ratio(16, 9);

    z-index: 0;
    min-width: 100%;
    border-radius: 0 12px 0 0;

    @include media-query-small {
      @include aspect-ratio(2, 1);

      min-width: var(--section-head-eyecatch-width);
      max-height: 600px;
      background-color: rgba(var(--primary__color--rgb), 0.3);
      border-radius: 0 20px 20px 0;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(var(--primary__color--rgb), 0.3);
    }
  }

  // .p-company__title-wrap
  &__title-wrap {
    position: relative;
    display: grid;
    gap: rr(8);
    justify-items: end;
    max-width: 100%;
    padding: rr(12) 0 0 rr(20);
    margin-top: rr(-25);
    margin-left: rr(20);
    text-align: right;
    background-color: var(--primary__color--hex);

    @include media-query-small {
      min-width: 100%;
      padding: rr(61) var(--section-head-title-padding);
      margin: 0;
      margin-left: calc(var(--section-head-eyecatch-width) * -1);
      background-color: transparent;
      border-right: rr(8) solid var(--white__primary--hex);
    }

    > * {
      color: var(--white__primary--hex);
    }
  }

  // .p-business__body
  &__body {
    padding: 0 var(--global-inner-large);
  }

  // .p-business__list
  &__list {
    display: grid;
    gap: rr(45);

    @include media-query-small {
      gap: rr(134);
    }
  }

  // .p-business__item
  &__item {
    display: flex;
    flex-flow: column;

    @include flex-gap(rr(24), 0);

    @include media-query-small {
      flex-flow: row wrap;
      align-items: flex-start;

      @include flex-gap(rr(40), rr(80));
    }

    &:nth-child(even) {
      @include media-query-small {
        flex-flow: row-reverse wrap;
      }
    }

    > * {
      @include media-query-small {
        flex: 1;
        min-width: 353px;
      }
    }
  }

  // .p-business__thumbnail
  &__thumbnail {
    @include aspect-ratio(2, 1);

    background-color: var(--primary__color--hex);

    &::before {
      @include media-query-small {
        padding-top: clamp(calc(100% * 0.5625), calc((768 / 1920) * 100vw), calc(100% * 0.75));
      }
    }
  }

  // .p-business__wrap
  &__wrap {
    display: grid;
    gap: rr(16);

    @include media-query-small {
      gap: rr(24);
    }
  }

  // .p-business__title
  &__title {
    @include inversion_main_color;

    color: var(--primary__color--hex);
  }

  // .p-business__text
  &__text {
    @include inversion_main_color;

    color: var(--primary__color--hex);
  }
}
