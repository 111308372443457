%g-post__p-set {
  font-size: var(--font-size-m);
  line-height: 2;
}

.g-post {
  overflow: hidden;

  h1 {
    font-size: rr(20);
    font-weight: bold;

    @at-root .l-main > & {
      margin: var(--global-margin) 0;
    }

    @include media-query-small {
      font-size: rr(40);
    }
  }

  h2 {
    position: relative;
    padding-bottom: rr(12);
    margin-top: rr(24);
    font-size: rr(18);
    font-weight: bold;
    color: var(--black__primary--hex);
    border-bottom: 2px solid #ccc;

    @include media-query-small {
      padding-bottom: rr(13);
      margin-top: rr(48);
      font-size: rr(28);
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: rr(64);
      height: 2px;
      content: "";
      background: var(--primary__color--hex);
      transform: translateY(100%);

      @include media-query-small {
        width: rr(100);
      }
    }
  }

  h3 {
    padding: rr(12) rr(16);
    font-size: rr(16);
    font-weight: bold;
    background-color: rgba(var(--primary__color--rgb), 0.1);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  h4 {
    padding: rr(8) rr(12);
    font-size: rr(16);
    border: 1px solid #ccc;
    border-right: none;
    border-left: none;

    @include media-query-small {
      padding: rr(12);
      font-size: rr(24);
    }
  }

  h5 {
    padding-left: rr(12);
    font-size: rr(16);
    font-weight: bold;
    border-left: 4px solid var(--primary__color--hex);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  h6 {
    font-size: rr(16);

    @include media-query-small {
      font-size: rr(20);
    }
  }

  p {
    @extend %g-post__p-set;
  }

  ul:not([class]),
  ol:not([class]) {
    li {
      @extend %g-post__p-set;
    }
  }

  iframe {
    &[src*="instagram"] {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
}
