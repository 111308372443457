.p-recruit-label {
  display: flex;
  align-items: start;

  // .p-recruit-label__title

  &__title {
    width: rr(72);
    padding: rr(4) rr(8);
    margin-right: rr(8);
    font-size: rr(12);
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    background-color: rgba(var(--primary__color--rgb), 0.1);
    border-radius: var(--global-radius);

    @include media-query-small {
      width: rr(88);
      padding: rr(4) rr(12);
      margin-right: rr(16);
      font-size: rr(14);
      line-height: 1.4;
    }
  }

  // .p-recruit-label__invert

  &__invert {
    color: var(--primary__color--hex);
  }

  // .p-recruit-label__text

  &__text {
    flex: 1;
    padding: rr(2) 0;
    font-size: rr(12);
    font-weight: bold;
    color: var(--black__primary--hex);

    @include media-query-small {
      padding: rr(4) 0;
      font-size: rr(14);
    }
  }
}
