%c-title--ja-set {
  font-size: rr(22);
  font-weight: 600;
  @include media-query-small {
    font-size: unquote("min(#{convert-large(96)}, 96px)");
  }
}

.c-title {
  // .c-title--en
  &--en {
    font-size: rr(38);
    font-weight: 600;
    color: var(--primary__color--hex);
    line-height: normal;
    @include media-query-small {
      font-size: unquote("clamp(#{rr(120)}, #{convert-large(148)}, #{rr(148)})");
    }
  }
  // .c-title--ja
  &--ja {
    font-size: rr(14);
    color: var(--black__primary--hex);
    font-weight: bold;
    @include media-query-small {
      font-size: rr(32);
    }
    // 代表メッセージと会社概要の見出しが日本語のみになった場合
    @at-root [class*="__clipping"]:only-child & {
      @extend %c-title--ja-set;
      color: var(--primary__color--hex);
    }
    // 事業紹介と沿革の見出しが日本語のみになった場合
    @at-root [class*="__title-wrap"] &:only-child {
      @extend %c-title--ja-set;
    }
  }
  // .c-title--sub
  &--sub {
    font-size: rr(24);
    color: var(--black__primary--hex);
    @include media-query-small {
      font-size: rr(48);
    }
  }
}
