.p-recruit {
  // .p-recruit__head

  &__head {
    position: relative;
    display: grid;
    justify-items: center;
    padding: rr(60) var(--global-inner-large);

    @include media-query-small {
      padding: rr(120) var(--global-inner-large);
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(var(--primary__color--rgb), 0.6);
    }

    @at-root .l-main > .p-recruit:first-child & {
      padding-top: calc(var(--header-height, #{rr(60)}) + #{rr(12)});

      @include media-query-small {
        padding-top: calc(var(--header-height, #{rr(120)}) + #{rr(24)});
      }
    }
  }

  // .p-recruit__title

  &__title {
    // .p-recruit__title--en

    &--en {
      font-size: rr(38);
      color: var(--white__primary--hex);

      @include media-query-small {
        font-size: rr(80);
      }
    }

    // .p-recruit__title--ja

    &--ja {
      margin-top: rr(-6);
      font-size: rr(14);
      font-weight: bold;
      color: var(--white__primary--hex);

      @include media-query-small {
        font-size: rr(24);
      }

      @at-root .p-recruit__head > &:first-child {
        font-size: rr(24);

        @include media-query-small {
          font-size: rr(80);
        }
      }
    }
  }

  // .p-recruit__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  // .p-recruit__inner

  &__inner {
    display: grid;
    gap: rr(24);
    padding: rr(24) var(--global-inner-large) rr(40);

    @include media-query-small {
      gap: rr(64);
      padding: rr(48) var(--global-inner-large) rr(112);
    }
  }

  // .p-recruit__list

  &__list {
    display: grid;
    gap: rr(16);

    @include media-query-small {
      grid-template-columns: repeat(2, 1fr);
      gap: rr(40);
      overflow: hidden;
    }
  }

  // .p-recruit__item

  &__item {
    display: grid;
  }

  // .p-recruit__link

  &__link {
    position: relative;
    background-color: var(--white__primary--hex);
    border: 1px solid #e0e0e0;
    border-radius: var(--global-radius);
  }

  // .p-recruit__title-wrap

  &__title-wrap {
    position: relative;
    z-index: 1;
    display: flex;
    min-height: rr(74);
    overflow: hidden;
    background-color: rgba(var(--primary__color--rgb), 0.05);

    @include media-query-small {
      min-height: rr(132);
    }
  }

  // .p-recruit_label-wrap

  &__label-wrap {
    display: grid;
    gap: rr(8);
    padding: rr(12);

    @include media-query-small {
      padding: rr(24);
    }
  }

  // .p-recruit__text

  &__text {
    flex: 1;

    @include clamp(3);

    margin: rr(8) rr(12);
    font-size: rr(14);
    font-weight: bold;
    line-height: 1.6;
    color: var(--black__primary--hex);
    transition: color 0.3s ease, -webkit-transform 0.3s ease;

    @include media-query-small {
      margin: rr(24);
      font-size: rr(18);
      line-height: 1.65;
    }

    @at-root .p-recruit__link:hover & {
      @include media-query-small {
        color: var(--primary__color--hex);
      }
    }
  }

  // .p-recruit__thumbnail

  &__thumbnail {
    @include aspect-ratio(3, 2);

    z-index: 1;
    width: rr(116);
    margin-right: -1px;
    border-radius: 0 var(--global-radius) 0 0;

    @include media-query-small {
      width: rr(180);
    }

    // .p-recruit__image

    .p-recruit__image {
      will-change: transform;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;

      @at-root .p-recruit__link:hover & {
        @include media-query-small {
          @include hover-scale-active;
        }
      }
    }
  }

  // .p-recruit__button

  &__button {
    display: grid;
    padding: rr(14) rr(24);
    background-color: var(--primary__color--hex);
    border-radius: var(--global-radius);

    @include media-query-small {
      justify-self: center;
      width: rr(485);
      padding: rr(16) rr(24);
    }
  }

  // .p-recruit__lead

  &__lead {
    @include inversion_main_color;

    display: grid;
    grid-template-columns: 1fr max-content;
    gap: rr(12);
    align-items: center;
    justify-items: center;
    font-size: rr(15);
    font-weight: bold;
    color: var(--primary__color--hex);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .p-recruit__icon

  &__icon {
    fill: var(--primary__color--hex);

    @include media-query-small {
      width: rr(12);
    }
  }
}
