.p-company {
  padding: var(--global-section-padding-large) 0;

  @at-root .p-message + & {
    @include media-query-small {
      margin-top: rr(-160);
    }
  }

  @at-root .p-contact + & {
    @include media-query-small {
      margin-top: rr(-160);
    }
  }

  &__inner {
    display: grid;
    gap: rr(28);

    @include media-query-small {
      gap: rr(120);
    }
  }

  // .p-company__head
  &__head {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    padding-right: var(--global-inner-large);

    @include media-query-small {
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  // .p-company__eyecatch
  &__eyecatch {
    @include aspect-ratio(16, 9);

    z-index: 0;
    min-width: 100%;
    border-radius: 0 12px 0 0;

    @include media-query-small {
      @include aspect-ratio(2, 1);

      min-width: var(--section-head-eyecatch-width);
      max-height: 600px;
      background-color: rgba(var(--primary__color--rgb), 0.3);
      border-radius: 0 20px 20px 0;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(var(--primary__color--rgb), 0.3);
    }
  }

  // .p-company__title-wrap
  &__title-wrap {
    position: relative;
    display: grid;
    gap: rr(8);
    justify-items: end;
    max-width: 100%;
    padding: rr(12) 0 0 rr(20);
    margin-top: rr(-25);
    margin-left: rr(20);
    text-align: right;
    background-color: var(--white__primary--hex);

    @include media-query-small {
      min-width: 100%;
      padding: rr(61) var(--section-head-title-padding);
      margin: 0;
      background-color: transparent;
    }

    &.js-original-node {
      @include media-query-small {
        z-index: -1;
        margin-left: calc(var(--section-head-eyecatch-width) * -1);
        border-right: rr(8) solid var(--primary__color--hex);
      }
    }

    &.js-clone-node {
      display: none;

      @include media-query-small {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        display: grid;
        transform: translateY(-50%);
      }

      > * {
        color: var(--white__primary--hex);
      }
    }
  }

  // .p-company__body
  &__body {
    display: flex;
    flex-direction: column;

    @include flex-gap(rr(24), 0);

    padding: 0 var(--global-inner-large);

    @include media-query-small {
      flex-flow: row wrap;

      @include flex-gap(rr(64));
    }

    > * {
      @include media-query-small {
        flex: 1;
      }
    }
  }

  // .p-company__list
  &__list {
    @include media-query-small {
      min-width: 436.266px;
    }
  }

  // .p-company__item
  &__item {
    padding: rr(16) 0;
    border-top: 1px solid var(--gray__primary--hex);

    @include media-query-small {
      padding: rr(16);
    }

    &:last-child {
      border-bottom: 1px solid var(--gray__primary--hex);
    }

    > p:only-child {
      width: 100%;
    }
  }

  // .p-company__label
  &__label {
    @include font-size-m;

    font-weight: 600;

    @include media-query-small {
      padding-right: rr(16);
    }

    &:only-child {
      @include media-query-small {
        padding-right: 0;
      }
    }
  }

  // .p-company__text
  &__text {
    line-height: 1.6;
  }

  // .p-company__wrap
  &__wrap {
    display: grid;
    gap: rr(16);
    align-content: start;

    @include media-query-small {
      min-width: 375.172px;
    }
  }

  // .p-company__iframe-wrap
  &__iframe-wrap {
    position: relative;
    max-height: rr(420);

    &::before {
      display: block;
      width: 100%;
      padding-bottom: 56.25%;
      content: "";
    }
  }

  // .p-company__iframe
  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // .p-company__description
  &__description {
    font-size: rr(14);
    line-height: 1.6;

    @include media-query-small {
      font-size: rr(16);
      line-height: 1.8;
    }
  }
}
