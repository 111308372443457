.p-post {
  // .p-post__inner
  &__inner {
    display: grid;
    gap: rr(16);
    padding: var(--global-section-padding-small);
    padding-right: var(--global-inner-small);
    padding-left: var(--global-inner-small);
    @include media-query-small {
      gap: rr(32);
    }
  }
}
