@keyframes scroll-animation {
  to {
    width: rr(0);
  }
}

.g-scroll {
  display: none;
  position: absolute;
  top: 50%;
  right: rr(32 + 22);
  left: auto;
  bottom: auto;
  transform: translateY(-50%) rotate(90deg);
  // ↑firefoxだとwriting-modeで不具合が出るため正方形にして回転で処理
  width: rr(25);
  height: rr(25);

  @include media-query-small {
    display: block;
  }

  // .g-scroll__text

  &__text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-right: rr(20);
    font-size: rr(16);
    font-weight: 600;
    white-space: nowrap;
    color: var(--black__primary--hex);
  }

  // .g-scroll__bar

  &__bar {
    will-change: width;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(0, -50%);
    width: rr(85);
    height: 2px;
    background-color: var(--black__primary--hex);
    animation: {
      name: scroll-animation;
      duration: 1.5s;
      timing-function: ease-in-out;
      delay: 0s;
      iteration-count: infinite;
      direction: reverse;
    }
  }
}
