.p-offer-head {
  position: relative;
  display: grid;
  align-items: end;

  @include media-query-small {
    min-height: rr(400);
  }

  // .p-offer-head__inner

  &__inner {
    display: grid;
    gap: rr(16);

    // var(--header-height) headerロゴがない場合は第2引数が反映されます。
    padding: calc(var(--header-height, #{rr(118)}) + #{rr(32)}) var(--global-inner-small) 0;
    overflow: hidden;

    @include media-query-small {
      gap: rr(24);

      // var(--header-height) headerロゴがない場合は第2引数が反映されます。
      padding: calc(var(--header-height, #{rr(118)}) + #{rr(64)}) var(--global-inner-small) 0;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(var(--primary__color--rgb), 0.1);
    }
  }

  // .p-offer-head__text-wrap

  &__text-wrap {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100vw;
    margin: 0 calc(50% - 50vw);

    .p-offer-head__eyecatch + & {
      height: 100%;
    }

    .p-offer-head__eyecatch + &::after {
      display: block;
      width: 100%;
      height: 50%;
      content: "";
      background-color: var(--white__primary--hex);
      transform: translateY(100%);
    }
  }

  // .p-offer-head__text

  &__text {
    bottom: 0;
    z-index: -1;
    display: block;
    font-size: rr(80);
    font-weight: bold;
    line-height: 1;
    color: rgba(var(--primary__color--rgb), 0.05);
    white-space: nowrap;
    transform: translateY(rr(10));

    @include media-query-small {
      font-size: rr(300);
      transform: translateY(rr(45));
    }

    .p-offer-head__eyecatch + .p-offer-head__text-wrap & {
      position: absolute;
      bottom: 50%;
    }
  }

  // .p-offer-head__eyecatch-wrap

  &__eyecatch-wrap {
    position: relative;
    order: 1;
  }

  // .p-offer-head__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);
  }

  // .p-offer-head__title-wrap

  &__title-wrap {
    display: grid;
    gap: rr(12);
    align-content: end;
  }

  // .p-offer-head__title

  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(40);
    }
  }

  // .p-offer-head__labels

  &__labels {
    display: grid;
    gap: rr(8);
  }
}
