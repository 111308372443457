.p-archive {
  padding: var(--global-section-padding-small) 0;

  // .p-archive__inner

  &__inner {
    display: grid;
    gap: rr(24);
    padding: 0 var(--global-inner-large);

    @include media-query-small {
      gap: rr(32);
      padding: 0 var(--global-inner-medium);
    }
  }

  // .p-archive__head

  &__head {
    @include flex-gap(rr(8));

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include media-query-small {
      @include flex-gap(rr(12));
    }

    .p-archive-sort {
      margin-left: auto;
    }
  }

  // .p-archive__list

  &__list {
    @include media-query-small {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rr(40);
      overflow: hidden;
    }

    @include media-query-medium {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  // .p-archive__item

  &__item {
    display: grid;
    align-content: start;
    border-bottom: 1px solid #e0e0e0;

    @include media-query-small {
      border: none;
    }

    &:where(:first-child) {
      border-top: 1px solid #e0e0e0;
    }
  }

  // .p-archive__link

  &__link {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    gap: rr(16);
    align-items: start;
    justify-content: space-between;
    height: 100%;
    padding: rr(16) 0;

    @include media-query-small {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      align-content: start;
      padding: 0;
    }
  }

  // .p-archive__thumbnail

  &__thumbnail {
    @include aspect-ratio(4, 3);

    z-index: 1;
    order: 1;
    width: rr(120);
    overflow: hidden;
    border-radius: var(--global-radius);

    @include media-query-small {
      @include aspect-ratio(2, 1);

      order: initial;
      width: auto;
    }
  }

  // .p-archive__image

  &__image {
    will-change: transform;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;

    @at-root .p-archive__link:hover & {
      @include media-query-small {
        @include hover-scale-active;
      }
    }
  }

  // p-archive__title-wrap

  &__title-wrap {
    display: grid;
    height: 100%;

    &:first-child {
      @include media-query-small {
        padding-top: rr(16);
        border-top: 2px solid #e0e0e0;
      }
    }
  }

  // .p-archive__title

  &__title {
    @include clamp(3);

    min-height: rr(45);
    font-size: rr(14);
    font-weight: bold;
    line-height: 1.6;
    color: var(--black__primary--hex);
    transition: color 0.3s ease, -webkit-transform 0.3s ease;

    @include media-query-small {
      @include clamp(2);

      min-height: rr(66);
      margin-top: rr(8);
      font-size: rr(20);
      line-height: 1.65;
    }

    @at-root .p-archive__link:hover & {
      @include media-query-small {
        color: var(--primary__color--hex);
      }
    }
  }

  &__description {
    display: none;

    @include media-query-small {
      @include clamp(3);

      min-height: rr(89);
      margin-top: rr(8);
      font-size: rr(16);
      line-height: 1.85;
      color: #656565;
    }
  }

  // .p-archive__category

  &__category {
    margin-top: rr(8);
    font-size: rr(12);
    font-weight: bold;
    color: var(--primary__color--hex);

    @include media-query-small {
      margin-top: rr(16);
      margin-bottom: 0;
      font-size: rr(14);
    }
  }

  // .p-archive__date

  &__date {
    align-self: end;
    margin-top: rr(2);
    font-size: rr(13);
    color: #818181;

    @include media-query-small {
      order: -1;
      margin-top: 0;
      font-size: rr(14);
      font-weight: bold;
    }
  }
}
