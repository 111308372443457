:root {
  --global-container: 100%;
  --global-container-small: 100%;
  --global-container-padding: #{rr(24)};
  --global-section-padding-small: #{rr(24)} 0 #{rr(64)};
  --global-section-padding-large: #{rr(64)};
  --global-inner-small: 6.5%;
  --global-inner-medium: 6.5%;
  --global-inner-large: 6.5%;
  --global-margin: #{rr(16)};
  --global-radius: 6px;
  --font-size-m: #{rr(15)};
  --line-height-m: 1.9;
  --break-container: calc(100% - 100vw / 2);
  @include media-query-small {
    --global-container-small: #{rr(960)};
    --global-container-padding: clamp(10%, 100vw, calc((100% - 1536px) / 2));
    --global-section-padding-x: 0;
    --global-section-padding-small: #{rr(32)} var(--global-section-padding-x) #{rr(96)};
    --global-section-padding-large: #{rr(160)};
    --global-inner-small: clamp(10%, 100vw, calc((100% - 960px) / 2));
    --global-inner-medium: clamp(10%, 100vw, calc((100% - 1280px) / 2));
    --global-inner-large: clamp(10%, 100vw, calc((100% - 1536px) / 2));
    --global-margin: #{rr(24)};
    --section-head-eyecatch-width: #{convert-large(1280)};
    --section-head-title-padding: #{convert-large(80)};
    --font-size-m: #{rr(20)};
    --line-height-m: 2.2;
    --max-width: calc(rr(1536) + var(--global-container-padding));
  }
}
// TODO: 不要な変数の削除
