.g-form {
  padding: var(--global-section-padding-small) 0;
  // .g-form__inner
  &__inner {
    display: grid;
    gap: rr(24);
    padding: 0 var(--global-inner-medium);
    @include media-query-small {
      gap: rr(40);
      --global-inner-medium: clamp(10%, 100vw, calc((100% - 1280px) / 2));
    }
  }
  // .g-form__text
  &__text {
    text-align: left;
    margin-bottom: rr(24);
    @include media-query-small {
      margin-bottom: rr(32);
    }
  }
  // .g-form__required {
  &__required {
    margin-bottom: rr(16);
  }
  // .g-form__wrap
  &__wrap {
    display: grid;
    gap: rr(12);
    padding: rr(16) 0;
    border-top: 1px solid var(--gray__primary--hex);
    @include media-query-small {
      width: 100%;
      display: table;
      table-layout: fixed;
      padding: rr(24) 0;
    }
    &:last-child {
      border-bottom: 1px solid var(--gray__primary--hex);
    }
  }

  // .g-form__label
  &__label {
    text-align: left;
    font-weight: 600;
    @include media-query-small {
      display: table-cell;
      width: 30%;
      font-size: rr(20);
      padding-right: rr(60);
      vertical-align: middle;
    }
    &:only-child {
      width: 100%;
      padding-right: 0;
    }
  }

  // .g-form__data
  &__data {
    display: grid;
    gap: rr(8);
    font-size: rr(16);
    @include media-query-small {
      display: table-cell;
      vertical-align: middle;
      font-size: rr(20);
    }
  }
  // .g-form__validation {
  &__validation {
    margin-top: rr(4);
    @include media-query-small {
      margin-top: rr(12);
    }
  }
  // .g-form__foot
  &__foot {
    margin-top: rr(32);
  }
  // .g-form__button
  &__button {
    display: grid;
    gap: rr(16);
    width: 100%;
    margin-top: rr(32);
    @include media-query-small {
      gap: rr(32);
      grid-auto-flow: column;
      justify-content: center;
      margin-top: rr(64);
    }
    @at-root .c-input--checkbox input ~ & {
      pointer-events: none;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
    }
    @at-root .c-input--checkbox input:checked ~ & {
      pointer-events: inherit;
      opacity: 1;
    }
  }
  // .g-form__agreement
  &__agreement {
    text-align: center;
    margin-bottom: rr(16);
  }
  // .g-form__check
  &__check {
    display: grid;
    justify-items: center;
    font-size: rr(14);
    text-align: center;
    @include media-query-small {
      font-size: rr(20);
    }
  }
}
