@mixin media-query-extra-small {
  @media print, screen and (min-width: $breakpoint-extra-small) {
    @content;
  }
}

@mixin media-query-small {
  @media print, screen and (min-width: $breakpoint-small) {
    @content;
  }
}

@mixin media-query-medium {
  @media print, screen and (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin media-query-large {
  @media print, screen and (min-width: $breakpoint-large) {
    @content;
  }
}

// modal-overlay
@mixin modal-overlay() {
  position: fixed;
  top: 0;
  left: 0;
  z-index: index($z-index, modal);
  display: none;
  width: calc(100vw - var(--scroll-bar));
  height: 100vh;

  &::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background: {
      repeat: repeat;
      position: 0 0, 2px 2px;
      size: 4px 4px;
      color: rgba(var(--black__primary--rgb), 0.8);
      image: linear-gradient(45deg, rgba(var(--black__primary--rgb), 0.1) 25%, transparent 25%, transparent 75%, rgba(var(--black__primary--rgb), 0.1) 75%, rgba(var(--black__primary--rgb), 0.1)), linear-gradient(45deg, rgba(var(--black__primary--rgb), 0.1) 25%, transparent 25%, transparent 75%, rgba(var(--black__primary--rgb), 0.1) 75%, rgba(var(--black__primary--rgb), 0.1));
    }
  }
}

// object-fit
@mixin object-fit($value) {
  object-fit: $value;
  object-position: center;
  font-family: "object-fit: #{$value}; object-position: center;";
}

// 三点リーダー
@mixin clamp($line) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;

  // paddingだと突き抜けるのでborder: transparent で対応
}

@mixin font-size-m() {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  letter-spacing: 0.05em;
}

// backgroundプロパティ
@mixin background($background-position, $background-size, $background-repeat) {
  background-repeat: $background-repeat;
  background-position: $background-position;
  background-size: $background-size;
}

// position位置調整
@mixin position($position, $top, $left) {
  position: $position;
  top: $top;
  left: $left;
  -webkit-transform: translateY(-$top) translateX(-$left);
  transform: translateY(-$top) translateX(-$left);
}

// z-index
@mixin z-index($z-index-name) {
  z-index: map-get($z-index-layer, $z-index-name);
}

@mixin aspect-ratio($width, $height, $max-width: none) {
  position: relative;
  overflow: hidden;

  @if $max-width != "none" {
    max-width: $max-width + px;
  }

  &::before {
    float: left;
    padding-top: round(($height / $width) * 100% * 100) / 100;
    content: "";
  }

  &::after {
    display: block;
    clear: both;
    content: "";
  }

  > img[class*="__image"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// 引数にオーバーレイのopacityを記述
@mixin background__dot($opacity: 0.2) {
  background: {
    color: rgba(var(--black__primary--rgb), $opacity);
    image: repeating-linear-gradient(0deg, rgba(242, 242, 242, 0.15) 0 1px, transparent 1px 2px), repeating-linear-gradient(90deg, rgba(242, 242, 242, 0.15) 0 1px, transparent 1px 2px);
  }
}

@mixin inversion_main_color() {
  filter: invert(100%) grayscale(100%) contrast(100);
}

@mixin flex-gap($row-gap, $column-gap: $row-gap) {
  @if $row-gap == 0 {
    margin-top: 0;
  } @else {
    margin-top: calc(#{$row-gap} * -1);
  }

  @if $column-gap == 0 {
    margin-left: 0;
  } @else {
    margin-left: calc(#{$column-gap} * -1);
  }

  & > * {
    @if $row-gap == 0 {
      margin-top: 0;
    } @else {
      margin-top: #{$row-gap};
    }

    @if $column-gap == 0 {
      margin-left: 0;
    } @else {
      margin-left: #{$column-gap};
    }
  }
}

@mixin hover-scale-active() {
  cursor: pointer;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  transform: scale(1.04);
}

@mixin hover-scale-still() {
  cursor: pointer;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

// 親要素を無視し、画面の幅いっぱいにbackground-colorを配置する。 https://twitter.com/ChallengesCss/status/1534494160589307904?s=20&t=F_FFdD-TbyRURoBlcMfisw
@mixin full-screen-width-background($color: rgba(var(--black__primary--rgb), 0.5)) {
  clip-path: inset(0 -100vmax);
  background-color: $color;
  box-shadow: 0 0 0 100vmax $color;
}
