.p-youtube {
  background-color: rgba(var(--black__primary--rgb), 0.98);

  // .p-youtube__inner
  &__inner {
    padding: 0;
    margin: 0 auto;

    @include media-query-small {
      padding: rr(80) var(--global-inner-small);
    }

    // youtubeブロック専用
    @at-root .l-main > .p-youtube:first-child & {
      padding-top: 0;
    }

    // rawhtmlブロック専用
    @at-root .l-main > *:first-child:not([class]) .p-youtube & {
      padding-top: var(--header-height, #{var(--global-section-padding-large)});
    }
  }

  // .p-youtube__thumbnail
  &__thumbnail {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
