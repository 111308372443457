%g-button-set {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: rr(12) rr(32);
  font-size: rr(18);
  font-weight: 600;

  @include media-query-small {
    width: rr(360);
    padding: rr(20) rr(40);
    font-size: rr(20);
  }
}

.g-button {
  &-forward {
    @extend %g-button-set;

    background-color: var(--primary__color--hex);
    border-radius: 6px;

    // .g-button-forward__text
    &__text {
      @include inversion_main_color;

      color: var(--primary__color--hex);
    }

    // .g-button-forward__icon
    &__icon {
      position: absolute;
      right: rr(14);
      fill: var(--white__primary--hex);

      @include media-query-small {
        right: rr(18);
        width: rr(10);
      }
    }
  }

  &-home,
  &-back {
    @extend %g-button-set;

    color: var(--black__primary--hex);
    border: 2px solid var(--primary__color--hex);
    border-radius: 6px;
  }

  // .g-button-unlock
  &-unlock {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: rr(54);
    padding: rr(8) rr(24);
    color: var(--white__primary--hex);
    background-color: var(--primary__color--hex-darken);
    border-radius: 6px;

    @include media-query-small {
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: rr(96);
      padding: rr(20) rr(32);
    }

    // .g-button-unlock__title-en
    &__title-en {
      font-size: rr(24);
      font-weight: 600;

      @include media-query-small {
        font-size: rr(36);
      }
    }

    // .g-button-strong__title-ja
    &__title-ja {
      font-size: rr(24);
      font-weight: 600;

      @include media-query-small {
        font-size: rr(48);
      }

      @at-root .g-button-unlock__title-en + & {
        font-size: rr(14);

        @include media-query-small {
          font-size: rr(18);
        }
      }
    }
  }

  // .g-button-strong
  &-strong {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    padding: rr(8) rr(48) rr(8) rr(24);
    color: var(--black__primary--hex);
    background-color: var(--white__primary--hex);
    border-radius: 6px;

    @include media-query-small {
      justify-content: left;
      width: 100%;
      padding: rr(20) rr(64) rr(20) rr(32);
    }

    @include media-query-small {
      justify-content: center;
    }

    // .g-button-strong__title-en
    &__title-en {
      font-size: rr(24);
      font-weight: 600;

      @include media-query-small {
        font-size: rr(36);
      }
    }

    // .g-button-strong__icon
    &__icon {
      position: absolute;
      top: 50%;
      right: rr(6);
      fill: var(--black__primary--hex);
      transform: translateY(-50%) translateX(-1em);

      @include media-query-small {
        right: rr(12);
        width: rr(14);
        height: rr(18);
      }
    }
  }
}
