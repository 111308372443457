.l-main {
  position: relative;
  z-index: index($z-index, main);
  width: 100%;
  max-width: 100%;

  // rawhtmlブロックが最初の要素の場合
  & > *:first-child:not([class]):not(root) {
    padding-top: 0;
  }
}
