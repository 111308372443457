.p-news {
  padding: rr(48) var(--global-inner-large) rr(40);
  background-color: rgba(var(--primary__color--rgb), 0.02);

  @include media-query-small {
    padding: rr(112) var(--global-inner-large);
  }

  // .p-news__inner

  &__inner {
    display: grid;
    gap: rr(24);

    @include media-query-small {
      gap: rr(92);
    }
  }

  // .p-news__head

  &__head {
    position: relative;
    display: grid;
    justify-items: center;
  }

  // .p-news__title
  &__title {
    // .p-news__title-en

    &--en {
      display: grid;
      justify-items: center;
      font-size: rr(38);
      color: var(--primary__color--hex);

      @include media-query-small {
        font-size: rr(80);
      }
    }

    // .p-news__title-ja

    &--ja {
      font-size: rr(14);
      font-weight: bold;

      @include media-query-small {
        font-size: rr(24);
      }

      &:first-child {
        font-size: rr(24);

        @include media-query-small {
          font-size: rr(48);
        }
      }
    }
  }

  // .p-news__body
  &__body {
    display: grid;
    gap: rr(24);

    @include media-query-small {
      gap: rr(64);
    }
  }

  // .p-news__list

  &__list {
    @include media-query-small {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rr(40);
      overflow: hidden;
    }

    @include media-query-medium {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  // .p-news__item

  &__item {
    display: grid;
    align-content: start;
    border-bottom: 1px solid #e0e0e0;

    @include media-query-small {
      border: none;
    }

    &:where(:first-child) {
      border-top: 1px solid #e0e0e0;
    }
  }

  // .p-news__link

  &__link {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    gap: rr(16);
    align-items: start;
    justify-content: space-between;
    height: 100%;
    padding: rr(16) 0;

    @include media-query-small {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      align-content: start;
      padding: 0;
    }
  }

  // .p-news__thumbnail

  &__thumbnail {
    @include aspect-ratio(4, 3);

    z-index: 1;
    order: 1;
    width: rr(120);
    overflow: hidden;
    border-radius: var(--global-radius);

    @include media-query-small {
      @include aspect-ratio(2, 1);

      order: initial;
      width: auto;
    }
  }

  // .p-news__image

  &__image {
    will-change: transform;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;

    @at-root .p-news__link:hover & {
      @include media-query-small {
        @include hover-scale-active;
      }
    }
  }

  // p-news__title-wrap

  &__title-wrap {
    display: grid;
    height: 100%;

    &:first-child {
      @include media-query-small {
        padding-top: rr(16);
        border-top: 2px solid #e0e0e0;
      }
    }
  }

  // .p-news__title

  &__title {
    @include clamp(3);

    min-height: rr(45);
    font-size: rr(14);
    font-weight: bold;
    line-height: 1.6;
    color: var(--black__primary--hex);
    transition: color 0.3s ease, -webkit-transform 0.3s ease;

    @include media-query-small {
      @include clamp(2);

      min-height: rr(66);
      margin-top: rr(8);
      font-size: rr(20);
      line-height: 1.65;
    }

    @at-root .p-news__link:hover & {
      @include media-query-small {
        color: var(--primary__color--hex);
      }
    }
  }

  &__description {
    display: none;

    @include media-query-small {
      @include clamp(3);

      min-height: rr(89);
      margin-top: rr(8);
      font-size: rr(16);
      line-height: 1.85;
      color: #656565;
    }
  }

  // .p-news__category

  &__category {
    margin-top: rr(8);
    font-size: rr(12);
    font-weight: bold;
    color: var(--primary__color--hex);

    @include media-query-small {
      margin-top: rr(16);
      margin-bottom: 0;
      font-size: rr(14);
    }
  }

  // .p-news__date

  &__date {
    align-self: end;
    margin-top: rr(2);
    font-size: rr(13);
    color: #818181;

    @include media-query-small {
      order: -1;
      margin-top: 0;
      font-size: rr(14);
      font-weight: bold;
    }
  }

  // .p-news__button

  &__button {
    display: grid;
    padding: rr(14) rr(24);
    background-color: var(--primary__color--hex);
    border-radius: var(--global-radius);

    @include media-query-small {
      justify-self: center;
      width: rr(485);
      padding: rr(16) rr(24);
    }
  }

  // .p-news__lead

  &__lead {
    @include inversion_main_color;

    display: grid;
    grid-template-columns: 1fr max-content;
    gap: rr(12);
    align-items: center;
    justify-items: center;
    font-size: rr(15);
    font-weight: bold;
    color: var(--primary__color--hex);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .p-news__icon

  &__icon {
    fill: var(--primary__color--hex);

    @include media-query-small {
      width: rr(12);
    }
  }
}
