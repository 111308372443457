$number-of-shutter-line: 7; // lineの数
$shutter-line-height: 100% / $number-of-shutter-line; // lineの高さ
// line共通スタイル
%l-shutter__line-set {
  will-change: transition;
  transition: transform 0.25s cubic-bezier(0.45, 0.05, 0.3, 0.93) 0s;
  @include media-query-small {
    transition: transform 0.45s cubic-bezier(0.45, 0.05, 0.3, 0.93) 0s;
  }
}

.l-shutter {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  // アニメーションしている時以外は上に持ってこない
  z-index: -1;
  &.js-shutter--in,
  &.js-shutter--out {
    z-index: index($z-index, shutter);
  }
  // .l-shutter__line-wrap
  &__line-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  // .l-shutter__line
  &__line {
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(#{$shutter-line-height} + 2px); // windowsでちょっと隙間があるので2px程追加
    background: var(--white__primary--hex);
    transform: translateX(100%);
    @for $i from 1 through $number-of-shutter-line {
      &:nth-child(#{$i}) {
        // 0から $shutter-line-height 分ずつ増やす
        top: #{($shutter-line-height * $i) - $shutter-line-height};
      }
    }
    // in animation
    @at-root .l-shutter.js-shutter--in & {
      @extend %l-shutter__line-set;
      transform: translateX(0);
      @for $i from 1 through $number-of-shutter-line {
        &:nth-child(#{$i}) {
          // .45から0.05ずつ減らす
          transition-delay: #{0.5s - (0.05s * $i)};
        }
      }
    }
    // out animation
    @at-root .l-shutter.js-shutter--out & {
      @extend %l-shutter__line-set;
      transform: translateX(-100%);
      @for $i from 1 through $number-of-shutter-line {
        &:nth-child(#{$i}) {
          // .45から0.05ずつ減らす
          transition-delay: #{0.5s - (0.05s * $i)};
        }
      }
    }
  }
}
