.p-contact {
  @include media-query-small {
    padding: var(--global-section-padding-large) var(--global-inner-large);
  }

  @at-root .p-message + & {
    @include media-query-small {
      margin-top: rr(-160);
    }
  }

  @at-root .p-company + & {
    @include media-query-small {
      margin-top: rr(-160);
    }
  }

  @at-root .l-main > &:first-child:not(:root) {
    padding-top: calc(var(--header-height, #{rr(80)}));

    @include media-query-small {
      padding: var(--global-section-padding-large) var(--global-inner-large);
    }
  }

  // .p-contact__inner
  &__inner {
    display: grid;
    gap: rr(24);
    padding: var(--global-section-padding-large) var(--global-inner-large);
    background-color: var(--primary__color--hex);

    @include media-query-small {
      grid-template-columns: repeat(auto-fit, minmax(#{rr(400)}, 1fr));
      gap: rr(32) rr(64);
      align-items: center;
      padding: rr(96) rr(80);
    }
  }

  // .p-contact__wrap
  &__wrap {
    display: grid;
    gap: rr(12);
  }

  // .p-contact__title
  &__title {
    @include inversion_main_color;

    font-size: rr(32);
    color: var(--primary__color--hex);
    text-align: center;

    @include media-query-small {
      font-size: rr(46);
      text-align: left;
    }
  }

  // .p-contact__text
  &__text {
    @include inversion_main_color;

    font-size: rr(16);
    font-weight: 600;
    color: var(--primary__color--hex);
    text-align: center;

    @include media-query-small {
      font-size: rr(20);
      text-align: left;
    }
  }

  // .p-contact__link
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rr(8) rr(48);
    font-size: rr(24);
    font-weight: 600;
    color: var(--black__primary--hex);
    background-color: var(--white__primary--hex);
    border-radius: 6px;

    @include media-query-small {
      justify-content: left;
      width: 100%;
      padding: rr(18.5) rr(96) rr(18.5) rr(48);
      font-size: rr(36);
    }
  }

  // .p-contact__icon
  &__icon {
    position: absolute;
    top: 50%;
    right: rr(24);
    transform: translateY(-50%);

    @include media-query-small {
      right: rr(40);
    }
  }
}
