html {
  min-height: 0;
  min-height: 100vh; // ios safari urlバーの余白対策
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: calc(0.0625rem + ((1vw - 0.01px) * 3.7618));

  @include media-query-extra-small {
    font-size: calc(1rem + ((1vw - 3.75px) * 0.8163));
  }

  @include media-query-small {
    font-size: calc(1rem + ((1vw - 16px) * 0.5495));
  }

  @include media-query-large {
    font-size: 16px;
  }
}

body {
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

  --wf-font-family--secondary: noto sans jp;

  position: relative;
  font-family: var(--wf-font-family--primary), var(--wf-font-family--secondary), "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.05em;
  -webkit-print-color-adjust: exact;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.js-fixed-scroll {
    position: fixed;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
}

button,
input,
select,
textarea {
  cursor: pointer;
}

a:not(a[class]) {
  font-weight: 600;
  color: var(--primary__color--hex-darken);
  text-decoration: underline;
}

:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid var(--black__primary--hex);
}

iframe {
  border: none;
}

p {
  @include font-size-m;
}

h3 {
  font-size: rr(18);

  @include media-query-small {
    font-size: rr(34);
  }
}

// webfontが和文もしくは和欧混合の場合
:not(:root)[data-string] {
  line-height: var(--wf-line-height__ja--sp);

  @include media-query-small {
    line-height: var(--wf-line-height__ja--pc);
  }
}

// webfontが欧文の場合
:not(:root)[data-string="alphabet"] {
  line-height: var(--wf-line-height__en--sp);

  @include media-query-small {
    line-height: var(--wf-line-height__en--pc);
  }
}
