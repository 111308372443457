.c-icon {
  // .c-icon--menu
  &--menu {
    width: rr(24);
    height: rr(16);
    @include media-query-small {
      width: rr(24);
      height: rr(10);
    }
  }
  // .c-icon--close
  &--close {
    width: rr(16);
    height: rr(16);
    @include media-query-small {
      width: rr(17);
      height: rr(17);
    }
  }
  // .c-icon--arrow
  &--arrow {
    width: rr(8);
    height: rr(13);
    @include media-query-small {
      width: rr(16);
      height: rr(26);
    }
  }
  // .c-icon--required
  &--required {
    color: var(--red__primary--hex);
  }
  // .c-icon--last-page
  &--last-page {
    width: rr(8);
    height: rr(8);
  }
  // .c-icon--apps
  &--apps {
    width: rr(8);
    height: rr(8);
  }
}
