.p-post-sns {
  @include media-query-small {
    margin-top: rr(16);
    margin-bottom: rr(-16);
  }
  // .p-post-sns__inner

  &__inner {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 16px;
    padding: rr(16) rr(32);
    background-color: #f7f7f7;

    @include media-query-small {
      grid-template-columns: repeat(2, 1fr);
      max-width: rr(528);
      padding: rr(20) rr(32);
      margin: 0 auto;
      justify-content: center;
    }
  }

  // .p-post-sns__text

  &__text {
    font-weight: bold;
    font-size: rr(14);
    line-height: 1.6;
    @include media-query-small {
      text-align: right;
      font-size: rr(16);
    }
  }

  // .p-post-sns__list

  &__list {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, max-content);
  }

  // .p-post-sns__item

  &__item {
    width: 32px;
  }

  // .p-post-sns__link

  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }

  // .p-post-sns__image

  &__image {
    width: 32px;
  }
}
