.p-service {
  padding: rr(48) var(--global-inner-large) rr(40);

  @include media-query-small {
    padding: rr(112) var(--global-inner-large);
  }

  // .p-service__inner

  &__inner {
    display: grid;
    gap: rr(24);

    @include media-query-small {
      gap: rr(92);
    }
  }

  // .p-service__head

  &__head {
    position: relative;
    display: grid;
    justify-items: center;
  }

  // .p-service__title

  &__title {
    // .p-service__title--en

    &--en {
      display: grid;
      justify-items: center;
      font-size: rr(38);
      color: var(--primary__color--hex);

      @include media-query-small {
        font-size: rr(80);
      }
    }

    // .p-service__title--ja

    &--ja {
      font-size: rr(14);
      font-weight: bold;

      @include media-query-small {
        font-size: rr(24);
      }

      &:first-child {
        font-size: rr(24);

        @include media-query-small {
          font-size: rr(48);
        }
      }
    }
  }

  // .p-service__list

  &__list {
    @include flex-gap(rr(16));

    display: flex;
    flex-flow: column;

    @include media-query-small {
      @include flex-gap(rr(32));

      flex-flow: row wrap;
      justify-content: center;
    }
  }

  // .p-service__item

  &__item {
    display: grid;
    gap: rr(12);

    @include media-query-small {
      gap: rr(16);
      align-content: start;
      width: calc((100% - #{rr(32 * 2)}) / 2); // (100% - margin-leftの値 * liの数) / liの数
    }

    @include media-query-medium {
      width: calc((100% - #{rr(32 * 3)}) / 3); // (100% - margin-leftの値 * liの数) / liの数
    }
  }

  // .p-service__title

  &__title {
    display: grid;
    justify-content: center;
    padding: rr(14) var(--global-inner-large);
    font-size: rr(16);
    font-weight: bold;
    color: #222;
    background-color: rgba(var(--primary__color--rgb), 0.1);
    border-radius: var(--global-radius);

    @include media-query-small {
      align-items: center;
      padding: rr(16) rr(32);
      font-size: rr(24);
      line-height: 1.6;
    }
  }

  // .p-service__description

  &__description {
    font-size: rr(14);
    color: #656565;

    @include media-query-small {
      font-size: rr(18);
      line-height: 1.85;
    }
  }
}
