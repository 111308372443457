.c-text {
  // .c-text--validation
  &--validation {
    color: var(--red__primary--hex);
    font-size: rr(14);
    line-height: 1.6;
    @include media-query-small {
      font-size: rr(18);
    }
  }
}
