.p-message {
  padding: var(--global-section-padding-large) 0;

  @at-root .p-conpany + & {
    @include media-query-small {
      margin-top: rr(-160);
    }
  }

  @at-root .p-contact + & {
    @include media-query-small {
      margin-top: rr(-160);
    }
  }

  &__inner {
    display: grid;
    gap: rr(28);
    width: 100%;

    @include media-query-small {
      gap: rr(120);
    }
  }

  // .p-message__head
  &__head {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding-left: var(--global-inner-large);

    @include media-query-small {
      flex-flow: row-reverse nowrap;
      align-items: center;
    }
  }

  // .p-message__eyecatch
  &__eyecatch {
    @include aspect-ratio(16, 9);

    z-index: 0;
    min-width: 100%;
    border-radius: 12px 0 0;

    @include media-query-small {
      @include aspect-ratio(2, 1);

      min-width: var(--section-head-eyecatch-width);
      max-height: 600px;
      background-color: rgba(var(--primary__color--rgb), 0.3);
      border-radius: 20px 0 0 20px;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(var(--primary__color--rgb), 0.3);
    }
  }

  // .p-message__title-wrap
  &__title-wrap {
    position: relative;
    display: grid;
    gap: rr(8);
    justify-items: start;
    max-width: 100%;
    padding: rr(12) rr(20) 0 0;
    margin-top: rr(-25);
    margin-right: rr(20);
    background-color: var(--white__primary--hex);

    @include media-query-small {
      min-width: 100%;
      padding: rr(61) var(--section-head-title-padding);
      margin: 0;
      background-color: transparent;
    }

    &.js-original-node {
      @include media-query-small {
        z-index: -1;
        margin-right: calc(var(--section-head-eyecatch-width) * -1);
        border-left: rr(8) solid var(--primary__color--hex);
      }
    }

    &.js-clone-node {
      display: none;

      @include media-query-small {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        display: grid;
        transform: translateY(-50%);
      }

      > * {
        color: var(--white__primary--hex);
      }
    }
  }

  // .p-message__body
  &__body {
    display: flex;
    flex-flow: column;

    @include flex-gap(rr(16), 0);

    padding: 0 var(--global-inner-large);

    @include media-query-small {
      flex-flow: row wrap;

      @include flex-gap(rr(16), rr(80));
    }

    > * {
      flex: 1;
    }
  }

  // .p-message__title
  &__title {
    line-height: 1.8;

    @include media-query-small {
      min-width: 231px;
    }
  }

  .g-accordion {
    min-width: 986 / 1616 * 100%; // .g-accordion-width / .p-message__body-width * 100%
  }

  // .p-message__signature
  &__signature {
    text-align: right;

    @at-root .g-accordion--close & {
      display: none;
    }
  }
}
