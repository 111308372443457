.p-default-head {
  position: relative;
  display: grid;

  // .p-default-head__eyecatch-wrap

  &__eyecatch-wrap {
    position: relative;
    grid-area: 2/1;
    width: 100%;
    padding: 0 var(--global-inner-small) 0 0;

    @include media-query-small {
      padding: 0 clamp(6.5%, 100vw, calc((100% - 1280px) / 2));
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rr(62);
      content: "";
      background-color: rgba(var(--primary__color--rgb), 0.1);

      @include media-query-small {
        height: rr(270);
      }
    }
  }

  // .p-default-head__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);

    height: 100%;
    border-radius: 0 12px 12px 0;

    @include media-query-small {
      @include aspect-ratio(1280, 560);

      border-radius: 20px;
    }
  }

  // .p-default-head__overlay

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--black__primary--rgb), 0.4);
  }

  // .p-default-head__inner

  &__inner {
    position: relative;
    z-index: 1;
    grid-area: 1/1;
    align-self: end;
    padding: 0 var(--global-inner-small);

    .p-default-head__eyecatch-wrap + & {
      grid-area: 2/1;
      padding: 0 var(--global-inner-small) 0 0;
      color: var(--white__primary--hex);

      @include media-query-small {
        padding: 0 clamp(6.5%, 100vw, calc((100% - 1280px) / 2));
      }
    }
  }

  // .p-default-head__title-wrap

  &__title-wrap {
    padding: calc(var(--header-height, #{rr(118)}) + #{rr(32)}) 0 rr(28);

    @include media-query-small {
      padding: calc(var(--header-height, #{rr(118)}) + #{rr(64)}) 0 rr(54);
    }

    .p-default-head__eyecatch-wrap + .p-default-head__inner & {
      padding: rr(62) var(--global-inner-small) rr(16);

      @include media-query-small {
        padding: rr(270) rr(68) rr(68);
      }
    }
  }

  // .p-default-head__title

  &__title {
    font-size: rr(23);

    @include media-query-small {
      font-size: rr(40);
    }
  }

  // .p-default-head__background

  &__background {
    grid-area: 1/1;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--primary__color--rgb), 0.1);

    .p-default-head__eyecatch-wrap ~ & {
      height: var(--header-height, #{rr(67)});

      @include media-query-small {
        height: var(--header-height, #{rr(118)});
      }
    }
  }
}

.p-default-head .g-scroll:not(.p-default-head__eyecatch-wrap ~ .g-scroll) {
  @include media-query-small {
    top: calc(50% + #{rr(67)});
  }
}
