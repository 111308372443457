.p-post-head {
  position: relative;
  display: grid;
  align-items: end;

  @include media-query-small {
    min-height: rr(400);
  }

  // .p-post-head__inner

  &__inner {
    display: grid;
    gap: rr(16);

    // var(--header-height) headerロゴがない場合は第2引数が反映されます。
    padding: calc(var(--header-height, #{rr(118)}) + #{rr(32)}) var(--global-inner-small) 0;
    overflow: hidden;

    @include media-query-small {
      gap: rr(24);

      // var(--header-height) headerロゴがない場合は第2引数が反映されます。
      padding: calc(var(--header-height, #{rr(118)}) + #{rr(64)}) var(--global-inner-small) 0;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(var(--primary__color--rgb), 0.1);
    }
  }

  // .p-post-head__text-wrap
  &__text-wrap {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100vw;
    margin: 0 calc(50% - 50vw);

    .p-post-head__eyecatch + & {
      height: 100%;
    }

    .p-post-head__eyecatch + &::after {
      display: block;
      width: 100%;
      height: 50%;
      content: "";
      background-color: var(--white__primary--hex);
      transform: translateY(calc(100% + 1px)); //　メインカラーの座布団が表示されないよう少し位置をずらす。
    }
  }

  // .p-post-head__text

  &__text {
    bottom: 0;
    z-index: -1;
    display: block;
    font-size: rr(80);
    font-weight: bold;
    line-height: 1;
    color: rgba(var(--primary__color--rgb), 0.05);
    white-space: nowrap;
    transform: translateY(rr(10));

    @include media-query-small {
      font-size: rr(300);
      transform: translateY(rr(45));
    }

    .p-post-head__eyecatch + .p-post-head__text-wrap & {
      position: absolute;
      bottom: 50%;
    }
  }

  // .p-post-head__eyecatch-wrap

  &__eyecatch-wrap {
    position: relative;
    order: 1;
  }

  // .p-post-head__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);

    margin-bottom: calc(var(--post-eye-catch-height, 0) / -2);
  }

  // .p-post-head__title

  &__title {
    font-size: rr(20);

    @include media-query-small {
      font-size: rr(40);
    }
  }

  // .p-post-head__categories-wrap

  &__categories-wrap {
    display: grid;
    gap: rr(8);
  }

  // .p-post-head__categories

  &__categories {
    @include flex-gap(rr(4), rr(12));

    display: flex;
    flex-wrap: wrap;

    @include media-query-small {
      @include flex-gap(rr(4), rr(12));
    }
  }

  // .p-post-head__category

  &__category {
    display: grid;
    align-items: center;
    font-size: rr(13);
    font-weight: bold;
    color: var(--primary__color--hex);
    text-decoration: underline;

    @include media-query-small {
      font-size: rr(17);
    }

    &:visited {
      color: var(--primary__color--hex);
    }
  }

  // .p-post-head__times-wrap

  &__times-wrap {
    display: grid;
    gap: rr(8);
    align-content: end;
  }

  // .p-post-head__times

  &__times {
    display: grid;
    gap: rr(2);
  }

  // .p-post-head__time

  &__time {
    display: grid;
    font-size: rr(14);
    font-weight: bold;
    color: #818181;

    @include media-query-small {
      font-size: rr(18);
    }
  }
}
