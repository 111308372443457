%c-input-set {
  width: 100%;
  padding: rr(8) rr(12);
  font-size: rr(16);
  color: var(--black__primary--hex);
  background-color: #f2f2f2;
  border-radius: rr(6);

  @include media-query-small {
    font-size: rr(20);
  }
}

[class^="c-input"] {
  outline: 0;
}

.c-input {
  // .c-input--text
  &--text {
    @extend %c-input-set;

    @include media-query-small {
    }
  }

  // .c-input--select
  &--select {
    @extend %c-input-set;

    position: relative;
    padding: 0;
    background-color: transparent;
    border: 1px solid var(--gray__primary--hex);

    &::before {
      position: absolute;
      top: 50%;
      right: rr(16);
      display: block;
      width: rr(8);
      height: rr(8);
      margin-top: rr(-2);
      pointer-events: none;
      content: "";
      border-right: 2px solid var(--black__primary--hex);
      border-bottom: 2px solid var(--black__primary--hex);
      transform: rotate(45deg) translateY(-50%);
      transform-origin: 50% 0;
    }

    select {
      width: 100%;
      padding: rr(8) rr(12);
      color: inherit;
      outline: none;
      -webkit-appearance: none;
      appearance: none;
    }
  }

  // .c-input--radio
  &--radio {
    input {
      position: absolute;
      box-sizing: border-box;
      padding: 0;
      opacity: 0;
    }

    label {
      position: relative;
      display: inline-block;
      padding-left: rr(28);
      cursor: pointer;

      @include media-query-small {
        font-size: rr(20);
      }

      &::before {
        position: absolute;
        top: rr(3);
        left: 0;
        display: inline-block;
        width: rr(18);
        height: rr(18);
        text-align: center;
        cursor: pointer;
        content: "";
        border: 1px solid var(--gray__primary--hex);
        border-radius: 100%;
        transition: all 250ms ease;

        @include media-query-small {
          top: rr(7);
        }
      }
    }

    input:checked + label::before {
      background-color: var(--primary__color--hex);
      box-shadow: inset 0 0 0 2px var(--white__primary--hex);
    }
  }

  // .c-input--text-area
  &--text-area {
    @extend %c-input-set;

    min-height: rr(240);
    overflow: hidden;
    overflow-y: scroll;
    line-height: 1.8;
    resize: none;
  }

  // .c-input--checkbox
  &--checkbox {
    input {
      position: absolute;
      opacity: 0;
    }

    label {
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      width: auto;
      padding-left: rr(30);
      cursor: pointer;

      @include media-query-small {
        font-size: rr(20);
      }

      &::before {
        position: absolute;
        top: rr(11);
        left: 0;
        display: block;
        width: rr(18);
        height: rr(18);
        margin-top: rr(-8);
        content: "";
        background: var(--white__primary--hex);
        border: 1px solid var(--gray__primary--hex);
        border-radius: rr(4);
        transition: all 250ms ease;

        @include media-query-small {
          top: rr(14);
        }
      }

      &::after {
        position: absolute;
        top: rr(11);
        left: rr(6);
        display: block;
        width: rr(6);
        height: rr(10);
        margin-top: rr(-6);
        content: "";
        border-right: 2px solid var(--white__primary--hex);
        border-bottom: 2px solid var(--white__primary--hex);
        opacity: 0;
        transform: rotate(45deg);

        @include media-query-small {
          top: rr(14);
        }
      }
    }

    input:checked + label::before {
      background-color: var(--primary__color--hex);
      border-color: var(--primary__color--hex);
    }

    input:checked + label::after {
      opacity: 1;
    }
  }

  // .c-input--file
  &--file {
    @at-root [data-is-uploaded="true"] & {
      display: none;
    }

    @at-root [data-is-uploaded="false"] & {
      display: inline-block;
      justify-self: start;
      background-color: var(--primary__color--hex);
      border-radius: 6px;
    }

    & + & {
      @include media-query-small {
        margin-top: rr(16);
      }
    }

    input {
      display: none;
    }

    label {
      @include inversion_main_color;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: rr(8) rr(20);
      font-size: rr(15);
      color: var(--primary__color--hex);
      cursor: pointer;

      @include media-query-small {
        font-size: rr(18);
      }
    }

    // .c-input--file-item
    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      min-height: rr(40);

      @include media-query-small {
        display: block;
      }

      & + & {
        margin-top: rr(16);
      }
    }

    // .c-input--delete
    &-delete {
      display: block;
      margin-left: rr(8);
      color: var(--red__primary--hex);
      text-decoration: underline;
      white-space: nowrap;

      @include media-query-small {
        display: inline-block;
      }
    }
  }
}
