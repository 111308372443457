.p-offer {
  // .p-offer__inner

  &__inner {
    display: grid;
    padding: var(--global-inner-small) var(--global-inner-large) var(--global-section-padding-large);

    @include media-query-small {
      padding: rr(32) var(--global-inner-small) var(--global-section-padding-large);
    }
  }

  // .p-offer__list

  &__list {
    display: flex;
    flex-direction: column;
    padding: rr(16) 0;
    border-bottom: 1px solid #ccc;

    @include media-query-small {
      flex-direction: inherit;
      padding: rr(32) 0;
    }

    @at-root &:first-child {
      padding-top: 0;

      @include media-query-small {
        padding-top: rr(32);
      }
    }
  }

  // .p-offer__title

  &__title {
    margin-bottom: rr(8);
    font-weight: bold;
    text-align: left;

    @include media-query-small {
      min-width: rr(240);
      margin-right: rr(32);
      margin-bottom: 0;
      font-size: rr(20);
    }

    @at-root .p-offer__list > &:only-child {
      margin-bottom: 0;

      @include media-query-small {
        flex: 1;
        margin-right: 0;
      }
    }
  }

  // .p-offer__text

  &__text {
    font-size: rr(15);
    line-height: 1.75;

    @include media-query-small {
      font-size: rr(18);
    }
  }
}
