.p-offer-label {
  display: flex;
  align-items: start;

  @include media-query-small {
    grid-template-columns: rr(88) 1fr;
    gap: rr(16);
  }

  // .p-offer-label__title

  &__title {
    width: rr(72);
    padding: rr(4) rr(8);
    margin-right: rr(8);
    font-size: rr(12);
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    background-color: var(--white__primary--hex);
    border-radius: var(--global-radius);

    @include media-query-small {
      width: rr(88);
      padding: rr(4) rr(12);
      font-size: rr(14);
      line-height: 1.4;
    }
  }

  // .p-offer-label__invert

  &__invert {
    color: var(--primary__color--hex);
  }

  // .p-offer-label__text

  &__text {
    flex: 1;
    padding: rr(2) 0;
    font-size: rr(12);
    font-weight: bold;

    @include media-query-small {
      padding: rr(4) 0;
      font-size: rr(14);
    }
  }
}
