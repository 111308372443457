.p-instagram {
  // .p-instagram__head

  &__head {
    position: relative;
    display: grid;
    justify-items: center;
    padding: rr(60) var(--global-inner-large) rr(64);

    @include media-query-small {
      padding: rr(164) var(--global-inner-large) rr(200);
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(45deg, rgba(254, 212, 117, 1) 0%, rgba(229, 61, 93, 1) 50%, rgba(194, 49, 134, 1) 70%, rgba(156, 56, 187, 1) 100%);
      opacity: 0.7;
    }
  }

  // .p-instagram__title

  &__title {
    // .p-instagram__title--en

    &--en {
      font-size: rr(38);
      color: var(--white__primary--hex);

      @include media-query-small {
        font-size: rr(80);
      }
    }

    // .p-instagram__title--ja

    &--ja {
      margin-top: rr(-6);
      font-size: rr(14);
      font-weight: bold;
      color: var(--white__primary--hex);

      @include media-query-small {
        font-size: rr(24);
      }

      @at-root .p-instagram__head > &:first-child {
        font-size: rr(24);

        @include media-query-small {
          font-size: rr(80);
        }
      }
    }
  }

  // .p-instagram__inner

  &__inner {
    display: grid;
    gap: rr(24);
    padding: 0 var(--global-inner-medium) rr(40);
    margin-top: rr(-36);

    @include media-query-small {
      gap: rr(64);
      padding: 0 var(--global-inner-medium) rr(112);
      margin-top: rr(-92);
    }
  }

  // .p-instagram__list

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
  }

  // .p-instagram__item

  &__item {
    display: grid;
  }

  // .p-instagram__link

  &__link {
    display: block;
  }

  // .p-instagram__thumbnail

  &__thumbnail {
    @include aspect-ratio(1, 1);

    z-index: 1;
    overflow: hidden;
    background-color: #eee;
  }

  // .p-instagram__image

  &__image {
    will-change: transform;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;

    @at-root .p-instagram__link:hover & {
      @include media-query-small {
        @include hover-scale-active;
      }
    }

    &:not(.js-instagram__image--show) {
      display: none;
    }
  }

  // .p-instagram__button

  &__button {
    display: grid;
    padding: rr(14) rr(24);
    background-color: var(--primary__color--hex);
    border-radius: var(--global-radius);

    @include media-query-small {
      justify-self: center;
      width: rr(485);
      padding: rr(16) rr(24);
    }
  }

  // .p-instagram__lead

  &__lead {
    @include inversion_main_color;

    display: grid;
    grid-template-columns: 1fr max-content;
    gap: rr(12);
    align-items: center;
    justify-items: center;
    font-size: rr(15);
    font-weight: bold;
    color: var(--primary__color--hex);

    @include media-query-small {
      font-size: rr(24);
    }
  }

  // .p-instagram__icon

  &__icon {
    fill: var(--primary__color--hex);

    @include media-query-small {
      width: rr(12);
    }
  }
}
