.p-main-visual-low {
  position: relative;
  padding-bottom: rr(28);
  overflow: hidden;
  background-color: rgba(var(--primary__color--rgb), 0.05);

  @include media-query-small {
    padding-bottom: rr(40);
  }

  // .p-main-visual-low__eyecatch-wrap

  &__eyecatch-wrap {
    padding: var(--header-height, #{rr(67)}) var(--global-inner-small) 0;

    @include media-query-small {
      padding: clamp(87px, rr(90), rr(90)) var(--global-inner-small) 0;
    }
  }

  // .p-main-visual-low__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);

    overflow: hidden;
    border-radius: 6px;
  }

  // .p-main-visual-low__catchphrase-wrap

  &__catchphrase-wrap {
    position: relative;
    display: grid;
    justify-items: start;
    padding-top: var(--header-height, #{rr(118)});
    padding-right: calc(var(--global-inner-small) + #{rr(16)});

    @include media-query-small {
      padding-right: calc(var(--global-inner-small) + #{rr(60)});
      padding-left: clamp(#{rr(64)}, 100vw, calc((100% - 1080px) / 2));
    }

    .p-main-visual-low__eyecatch-wrap + & {
      padding-top: 0;
      margin-top: rr(-20);

      @include media-query-small {
        margin-top: rr(-73);
      }
    }
  }

  // .p-main-visual-low__catchphrase

  &__catchphrase {
    order: 1;
    min-height: rr(47);
    padding: rr(8) rr(16);
    margin-bottom: rr(-2);
    font-size: rr(22);
    font-weight: bold;
    line-height: 1.4;
    background-color: var(--white__primary--hex);

    @include media-query-small {
      min-height: clamp(60px, #{convert-large(120)}, #{rr(120)});
      padding: rr(20) rr(40);
      margin-bottom: rr(-12);
      font-size: clamp(12px, #{convert-large(57)}, #{rr(57)});
    }

    &:empty {
      display: none;
      background-color: transparent;

      @include media-query-small {
        display: block;
      }
    }
  }

  // .p-main-visual-low__company

  &__company {
    order: 2;
    padding: rr(4) rr(12);
    margin: 0 rr(16);
    font-size: rr(14);
    font-weight: bold;
    color: var(--white__primary--hex);
    background-color: var(--primary__color--hex);

    @include media-query-small {
      padding: rr(10) rr(24);
      margin: 0 convert-large(38);
      font-size: clamp(12px, convert-large(22), rr(22));
    }

    &:empty {
      background-color: transparent;
    }
  }

  // .p-main-visual-low__typography

  &__typography {
    position: absolute;
    bottom: 0;
    z-index: -1;
    font-size: convert-small(48);
    font-weight: bold;
    color: rgba(var(--primary__color--rgb), 0.05);
    white-space: nowrap;
    transform: translateY(calc(#{convert-small(75)} / 3.5));

    @include media-query-small {
      font-size: convert-large(200);
      transform: translateY(calc(#{convert-large(320)} / 3.5));
    }
  }
}
