$p-history__label-padding: 38;

%p-history__text-set {
  @include font-size-m;
  padding-left: rr(28);
}

.p-history {
  padding: var(--global-section-padding-large) 0;
  background-color: rgba(var(--primary__color--rgb), 0.05);
  &__inner {
    position: relative;
    display: grid;
    align-items: start;
    gap: rr(26);
    padding: 0 var(--global-inner-large);
    @include media-query-small {
      gap: rr(80) rr(40);
    }
    @include media-query-medium {
      grid-template-columns: 1fr 40%;
    }
  }
  &__sticky-wrap {
    @include media-query-small {
      align-self: stretch;
      display: flex;
    }
  }
  &__sticky {
    @include media-query-small {
      position: sticky;
      top: rr(80);
      align-self: start;
    }
  }
  // .p-history__title-wrap
  &__title-wrap {
    display: grid;
    gap: rr(8);
    @include media-query-small {
      align-content: center;
      padding: rr(61) 0;
      padding-left: rr(40);
      border-left: rr(8) solid var(--primary__color--hex);
    }
  }
  // .p-history__list
  &__list {
    position: relative;
    display: grid;
    gap: rr(12);
    padding: rr(20) 0;
    @include media-query-small {
      gap: rr(66);
      padding: rr(78) 0 rr(100);
    }
    &:first-child {
      @include media-query-medium {
        grid-column: 1 / -1;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: rr(6);
      height: 100%;
      border-radius: 20px;
      background-color: rgba(var(--black__primary--rgb), 0.1);
      @include media-query-small {
        width: rr(8);
      }
    }
  }
  // .p-history__item
  &__item {
    position: relative;
    display: grid;
    @include media-query-small {
      gap: rr(8);
    }
    &::before {
      content: "";
      position: absolute;
      top: rr(30 / 2);
      left: 0;
      transform: translate(0, -50%);
      width: rr(6);
      height: rr(6);
      border-radius: 50%;
      background-color: var(--black__primary--hex);
      @include media-query-small {
        top: rr(44 / 2);
        width: rr(8);
        height: rr(8);
      }
    }
  }
  // .p-history__time
  &__time {
    @extend %p-history__text-set;
    font-weight: 600;
    @include media-query-small {
      padding: 0;
      padding-left: rr($p-history__label-padding);
    }
  }
  // .p-history__text
  &__text {
    @extend %p-history__text-set;
    @include media-query-small {
      padding-left: rr($p-history__label-padding);
    }
  }
}
