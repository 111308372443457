$p-main-visual__padding-bottom: 108;

@keyframes p-main-visual__bar-animation {
  to {
    width: rr(0);
  }
}

.p-main-visual {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  min-height: 100vh;

  // padding-topはheaderのheight分いれる
  padding: rr(216) var(--global-inner-large) rr($p-main-visual__padding-bottom);
  margin: 0 calc(50% - 50vw);
  overflow: hidden;

  // ios safari
  @supports (-webkit-touch-callout: none) {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  @include media-query-small {
    // padding-topはheaderのheight分いれる
    padding: rr(264) rr(111) rr(140);
  }

  // .p-main-visual__eyecatch
  &__eyecatch {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--primary__color--hex);

    &::before {
      @include background__dot;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";

      $dot: 2;
      $space: 3;
      background: {
        image: repeating-linear-gradient(0deg, rgba(242, 242, 242, 0.15) 0 #{$dot}px, transparent #{$dot}px #{$space}px), repeating-linear-gradient(90deg, rgba(242, 242, 242, 0.15) 0 #{$dot}px, transparent #{$dot}px #{$space}px);
      }
    }
  }

  // .p-main-visual__image
  &__image {
    position: relative;
    z-index: -1;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  // .p-main-visual__wrap
  &__wrap {
    display: grid;
    color: var(--white__primary--hex);
  }

  // .p-main-visual__catch
  &__catch {
    order: 1;
    margin-bottom: rr(10);
    font-size: rr(16);
    font-style: italic;
    font-weight: 600;
    line-height: 1.25;

    @include media-query-small {
      font-size: rr(20);
    }
  }

  // .p-main-visual__title
  &__title {
    order: 2;
    font-size: rr(32);
    font-weight: 600;
    line-height: 1.4;

    @include media-query-small {
      font-size: rr(56);
    }
  }

  // .p-main-visual__name
  &__name {
    order: 3;
    margin-top: rr(4);
    font-size: rr(14);
    letter-spacing: 0.05em;

    @include media-query-small {
      font-size: rr(22);
    }
  }

  // .p-main-visual__scroll
  &__scroll {
    position: absolute;
    bottom: rr($p-main-visual__padding-bottom - 33);
    left: 50%;
    transform: translateX(-50%) rotate(90deg);

    @include media-query-small {
      top: 50%;
      right: rr(64);
      bottom: auto;
      left: auto;

      // ↑firefoxだとwriting-modeで不具合が出るため正方形にして回転で処理
      width: rr(25);
      height: rr(25);
      transform: translateY(-50%) rotate(90deg);
    }
  }

  // .p-main-visual__text
  &__text {
    position: absolute;
    display: block;
    font-size: 0;

    @include media-query-small {
      left: 50%;
      padding-right: rr(20);
      font-size: rr(16);
      font-weight: 600;
      color: var(--white__primary--hex);
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }

  // .p-main-visual__bar
  &__bar {
    position: absolute;
    top: 50%;
    left: 100%;
    width: rr(42);
    height: 2px;
    background-color: var(--white__primary--hex);
    transform: translate(0, -50%);
    will-change: width;
    animation: {
      name: p-main-visual__bar-animation;
      duration: 1.5s;
      timing-function: ease-in-out;
      delay: 0s;
      iteration-count: infinite;
      direction: reverse;
    }

    @include media-query-small {
      width: rr(85);
    }
  }
}
