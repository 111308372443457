.g-main-visual {
  // .g-main-visual__eyecatch

  &__eyecatch {
    @include aspect-ratio(2, 1);
    width: 100%;

    @include media-query-small {
      max-height: rr(400);
    }
  }
}
